import logo from './logo.svg';
import './App.css';

function Home() {
  return (
    <div className="home">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src="/img/900x400.png"
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">humanity</h1>
            <p>
              humanity est un jeu de strategie/ gestion scénarisé qui se déroule dans un futur proche
              tentez de sauvez l'humanité en créant un société responsable, mais tout le monde ne sera pas d'accord, vous aurez a faire face a divers enemis pour y parvenir,
              ce ne sera pas facile, il faudra d'abord survivre avant de construire, s'agrandir et amener une paix durable sur cette terre désorganisée,
              découvrez les évenements qui ont amené cette nouvelle terre, participez à son histoire seul ou a plusieurs joueurs, trouvez les personnes qui vont marquer le monde,
              decouvrez les technologies capable de faire basculer l'equilibre et enfin developpez l'avenir de l'humanité
            </p>
          </div>
        </div>
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <a href="/humanity/humanity_0.0.8/humanity.exe">download v 0.0.8</a>
            <p>Passage aux systeme de coordonnées 3d , ajout de reliefs, refonte du systeme de données, ajout de l'herbe , mis a jour des interfaces pour prendre en compte le level</p>
            <a href="/humanity/humanity_0.0.7/humanity.exe">download v 0.0.7</a>
            <p>première version testable pour les amis qui veulent debugger, démarrage du workflow de publication :) </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
